<template>
    <div id="MenuMobile">
        <nav id="nav">
            <ul id="list-items">
                <li
                    class="item"
                    v-for="item in items"
                    :key="item.id"
                >
                    <a 
                        class="link"
                        :href="item.href"
                        v-on:click="onClick()"
                    >
                        {{ item.name }}
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "MenuMobile",
        components: {

        },

        data() {
            return {
                items: [
                   {
                        id: 1,
                        name: "About",
                        href:  "#sectionFacts"
                    },
                    {
                        id: 2,
                        name: "Music",
                        href:  "#AreaCards"
                    },
                    {
                        id: 3,
                        name: "Contact",
                        href:  "#SectionContact"
                    }
                    ,
                    {
                        id: 4,
                        name: "Social Media",
                        href:  "#Footer"
                    }
                ]
            }
        
        
        
        
        },
        methods:{
        onClick()
        {
            
            document.getElementById("ButtonMobile").click();

        }

        }
    }
</script>

<style scoped>
    div#MenuMobile {
        width: 100%;
        flex: 1;
       
        background-color: var(--black);
        overflow: hidden;
    }

    nav#nav {
        margin-left: 2rem;
    }

    div#MenuMobile,
    ul#list-items
    {
        display: flex;
        flex-direction: column;
        justify-content: center;
        list-style-type: none; 
    }

    li.item {
        margin: 15px 0;
    }

    a.link {
        color: var(--white);

        font-size: 1.5rem;
        font-family: "Josefin Sans";
        text-decoration: none;
    }
</style>