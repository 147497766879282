import { createApp } from 'vue'
import App from './App.vue'

//createApp(App).mount('#app')
const app = createApp(App)

// const requestOptions = {
//   method: "GET",
//   headers: { "Content-Type": "application/json" },
//   mode: 'no-cors' 
//   };
//   try{

  // fetch(process.env.BASE_URL + "config.json", requestOptions)
  //   .then((response) => response.json())
  //   .then((config) => {
  //     // either use window.config
  //     window.config = config
  //     // or use [Vue Global Config][1]
  //     app.config.globalProperties.config = config
  //     // FINALLY, mount the app
  //     app.mount("#app")
  // })
  // }
  // catch(e){      
  
  // }
  // finally{

    var config = {
    
      "LastReleseURL": "https://open.spotify.com/embed/track/5FIMfdE5O7eOykdPYbnpM3?utm_source=generator",
      "token": "5894013224:AAFkObJYwxCS50ePK3igA6BRQGRAitz6Uxs",
      "chatId":   "142724084"
      }
       // either use window.config
       window.config = config
       // or use [Vue Global Config][1]
       app.config.globalProperties.config = config
       // FINALLY, mount the app
       app.mount("#app")
 // }